import { SvgIconProps } from '@material-ui/core';
import { FC, forwardRef } from 'react';
import { ReactComponent as ConfidenceIconSvg } from './confidence-icon.svg';
import { ReactComponent as ExpertiseIconSvg } from './expertise-icon.svg';
import { ReactComponent as FlexibilityIconSvg } from './flexibility-icon.svg';
import { ReactComponent as InnovationIconSvg } from './innovation-icon.svg';
import { ReactComponent as IntegrityIconSvg } from './integrity-icon.svg';
import { ReactComponent as QualityIconSvg } from './quality-icon.svg';
import { ReactComponent as SafetyIconSvg } from './safety-icon.svg';

export const ConfidenceIcon: FC<SvgIconProps> = forwardRef((props, ref) => <ConfidenceIconSvg ref={ref} {...props} />);
export const ExpertiseIcon: FC<SvgIconProps> = forwardRef((props, ref) => <ExpertiseIconSvg ref={ref} {...props} />);
export const FlexibilityIcon: FC<SvgIconProps> = forwardRef((props, ref) => <FlexibilityIconSvg ref={ref} {...props} />);
export const InnovationIcon: FC<SvgIconProps> = forwardRef((props, ref) => <InnovationIconSvg ref={ref} {...props} />);
export const IntegrityIcon: FC<SvgIconProps> = forwardRef((props, ref) => <IntegrityIconSvg ref={ref} {...props} />);
export const QualityIcon: FC<SvgIconProps> = forwardRef((props, ref) => <QualityIconSvg ref={ref} {...props} />);
export const SafetyIcon: FC<SvgIconProps> = forwardRef((props, ref) => <SafetyIconSvg ref={ref} {...props} />);
