export const Slide1 = require('images/main/Slide1.webp');
export const Slide2 = require('images/main/Slide2.webp');
export const Slide3 = require('images/main/Slide3.webp');
export const Slide4 = require('images/main/Slide4.webp');
export const Slide5 = require('images/main/Slide5.webp');
export const AboutUs = require('images/main/AboutUs.webp');
export const News = require('images/main/News.webp');
export const Expertise = require('images/main/Expertise.webp');
export const Projects = require('images/main/Projects.webp');
export const Careers = require('images/main/Careers.webp');
export const ContactUs = require('images/main/ContactUs.webp');
export const BgImage = require('images/main/BgImage.webp');

export const ConstructionGraphBg = require('images/main/ConstructionGraphBg.webp');
export const WirelessGraphBg = require('images/main/WirelesGraphBg.webp');
export const EnergyGraphBg = require('images/main/EnergyGraphBg.webp');
export const EngineeringGraphBg = require('images/main/EngineeringGraphBg.webp');
export const DeploymentGraphBg = require('images/main/DeploymentGraphBg.webp');
