import { NextPage } from 'next';
import React from 'react';
import { BusinessQualities, DeliveringIndustrySolutions, MainSlider, OurCompanyText, SiteMapSection } from 'containers/main';
import {
  ConstructionGraphBg,
  DeploymentGraphBg,
  EnergyGraphBg,
  EngineeringGraphBg,
  Slide1,
  Slide2,
  Slide3,
  Slide4,
  Slide5,
  WirelessGraphBg,
} from 'images/main';
import {
  ConfidenceIcon,
  ExpertiseIcon,
  FlexibilityIcon,
  InnovationIcon,
  IntegrityIcon,
  QualityIcon,
  SafetyIcon,
} from 'svgs/main';

const Main: NextPage = () => {
  return (
    <>
      <MainSlider
        sliderList={[
          {
            bgImage: Slide2,
            title: 'Engineering',
            text1: 'Making any project a success.',
            text2: 'We can make any concept come to life - from scratch.',
            text3: 'Our experts are there every step of the way with both aesthetics and functionality in mind.',
            href: '/expertise/engineering',
            bgGraphic: EngineeringGraphBg,
          },

          {
            bgImage: Slide1,
            title: 'Construction',
            text1: 'Building a better tomorrow.',
            text2: 'We are committed to making a real impact.',
            text3: 'Our crew is on a mission to assemble the infrastructure your community deserves.',
            href: '/expertise/construction',
            bgGraphic: ConstructionGraphBg,
          },
          {
            bgImage: Slide3,
            title: 'Wireless',
            text1: 'Bringing people together.',
            text2: 'We have what it takes to facilitate rock-solid broadband connections.',
            text3: 'Our commitment to innovation allows us to deliver cutting-edge network solutions.',
            href: '/expertise/communication/wireless',
            bgGraphic: WirelessGraphBg,
          },
          {
            bgImage: Slide4,
            title: 'Energy',
            text1: 'Keeping the lights on.',
            text2: 'We work tirelessly, fueling your day-to-day energy needs.',
            text3: 'Our experts provide tried-and-true energy services tailored to your requirements.',
            href: '/expertise/energy',
            bgGraphic: EnergyGraphBg,
          },
          {
            bgImage: Slide5,
            title: 'Technology Deployment',
            text1: 'Integrating modern innovations.',
            text2: 'We use avant-garde tech in all our processes.',
            text3: 'Our employees are fully equipped to carry out the installation, maintenance, and repair activities.',
            href: '/expertise/technology-deployment/gpm',
            bgGraphic: DeploymentGraphBg,
          },
        ]}
      />
      <DeliveringIndustrySolutions
        title={'Setting the Standard in Infrastructure Solutions'}
        description={
          'is a comprehensive service provider, specializing in advanced infrastructure solutions for Telecommunications, Electric Utilities, Oil & Gas, Water, Wireless, Engineering, and Technology Deployment sectors. Our expertise ensures innovative and reliable services across diverse industries, driving success and sustainability.'
        }
        orangeText={'Utilities One '}
      />
      <BusinessQualities
        list={[
          {
            icon: <SafetyIcon />,
            title: 'Safety',
            description:
              'Our top priority is the health and well-being of our team and customers, ensuring a safe and secure environment.',
          },
          {
            icon: <ConfidenceIcon />,
            title: 'Confidence',
            description:
              'We value our people, building strong, trustworthy connections that foster impactful business relationships.',
          },
          {
            icon: <QualityIcon />,
            title: 'Quality',
            description:
              'We pursue excellence in every project, maintaining high standards from small tasks to large-scale initiatives.',
          },
          {
            icon: <InnovationIcon />,
            title: 'Innovation',
            description:
              'We believe in modern solutions and technology, driving progress through exceptional and forward-thinking results.',
          },
          {
            icon: <IntegrityIcon />,
            title: 'Integrity',
            description:
              'We are committed to ethical conduct and transparency, building a foundation of trust and reliability in every service.',
          },
          {
            icon: <ExpertiseIcon />,
            title: 'Expertise',
            description:
              'With knowledge and experience, we handle challenges confidently, delivering proficient solutions every time.',
          },
          {
            icon: <FlexibilityIcon />,
            title: 'Flexibility',
            description:
              'By adapting to change, we guarantee efficient and flexible operations that meet diverse and evolving needs.',
          },
        ]}
      />
      <SiteMapSection />
      <OurCompanyText />
    </>
  );
};

export default Main;
