import React, { FC, ReactNode } from 'react';
import { Box, Container, Divider, Grid, Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

interface BusinessQualitiesProps {
  list: Array<{ icon: ReactNode; title: string; description: string }>;
}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      marginBottom: theme.spacing(8),
    },
    title: {
      color: theme.palette.primary.dark,
      fontWeight: 500,
    },
    description: {
      maxWidth: 350,
      margin: '0 auto',
    },
    divider: {
      backgroundColor: theme.palette.primary.main,
      maxWidth: 100,
      margin: '0 auto',
    },
  });
});

const BusinessQualities: FC<BusinessQualitiesProps> = ({ list }) => {
  const classes = useStyles();
  return (
    <Container fixed className={classes.root}>
      <Grid container justify="center" spacing={5}>
        {list.map(({ icon, title, description }, index) => (
          <Grid item key={index} xs={12} sm={6} md={3} data-aos="fade-up">
            <Box display="flex" justifyContent="center" mb={6}>
              {icon}
            </Box>
            <Typography align="center" variant="h4" className={classes.title}>
              {title}
            </Typography>
            <Box mb={4} mt={2}>
              <Divider className={classes.divider} />
            </Box>
            <Typography align="center" color="textSecondary" className={classes.description}>
              {description}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default BusinessQualities;
