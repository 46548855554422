import React, { FC } from 'react';
import { makeStyles, Theme, Box, Container, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(10, 0),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(16, 0),
    },
  },
  divider: {
    width: 100,
    height: 1,
    backgroundColor: theme.palette.primary.main,
    marginTop: theme.spacing(6),
  },
  text: {
    color: theme.palette.primary.dark,
    fontWeight: 500,
    margin: '0 auto',
    textAlign: 'center',
  },
  orangeText: {
    color: theme.palette.primary.main,
  },
}));

const OurCompanyText: FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Container fixed>
        <Box data-aos="fade-up">
          <Typography variant="h4" className={classes.text}>
            Our unwavering commitment is to deliver <span className={classes.orangeText}>world-class services</span> that
            consistently meet and exceed customer expectations.
          </Typography>
          <Box className={classes.divider} margin="0 auto" />
        </Box>
      </Container>
    </Box>
  );
};

export default OurCompanyText;
