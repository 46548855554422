import React, { FC } from 'react';
import { Box, Container, Divider, Grid, Theme, Typography, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { BgImage } from 'images/main';

interface DeliveringIndustrySolutionsProps {
  title: string;
  description: string;
  orangeText: string;
}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      backgroundImage: `url(${BgImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      marginTop: theme.spacing(12),
      [theme.breakpoints.up('sm')]: {
        backgroundSize: 450,
        padding: theme.spacing(10, 0, 12),
      },
    },
    title: {
      color: theme.palette.primary.dark,
      fontWeight: 500,
    },
    text: {
      color: theme.palette.primary.dark,
      lineHeight: 1.6,
      [theme.breakpoints.up('sm')]: {
        lineHeight: 1.8,
      },
    },
    divider: {
      backgroundColor: theme.palette.primary.main,
      maxWidth: 100,
      margin: '0 auto',
    },
    orangeText: {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
  });
});

const DeliveringIndustrySolutions: FC<DeliveringIndustrySolutionsProps> = ({ title, description, orangeText }) => {
  const classes = useStyles();
  const isSmallUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  return (
    <Container fixed className={classes.root} id={'core-values'}>
      <Grid container direction="row" justify="center">
        <Grid item md={8}>
          <Typography align="center" variant={isSmallUp ? 'h2' : 'h3'} className={classes.title} data-aos="fade-up">
            {title}
          </Typography>
          <Box mb={7} />
        </Grid>
        <Grid xs={12} item>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item md={9}>
          <Box mt={6} />
          <Typography align="center" variant="h5" className={classes.text} data-aos="fade-up" data-aos-delay="200">
            <span className={classes.orangeText}>{orangeText}</span>
            {description}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default DeliveringIndustrySolutions;
